



























import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    defaultFullDocument,
    FullDocument,
} from '@/types/front-data-types/full-docs';

import Avatar from '@/components/block/avatar.vue';

@Component({ name: 'ActionView', components: { Avatar } })
export default class ActionView extends Vue {
    @Prop({ default: defaultFullDocument }) readonly document: FullDocument;
}
