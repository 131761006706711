

































import { Component, Vue } from 'vue-property-decorator';

import ArMainContainer from '@/components/section/ar-main-container.vue';

import ArSearch from '@/components/form/ar-search.vue';
import ArButton from '@/components/form/ar-button.vue';

import MiniDocs from '@/components/block/mini-docs.vue';
import MainFilters from '@/components/block/main-filters.vue';

@Component({
    name: 'MainLab',
    components: {
        ArSearch,
        ArButton,
        MiniDocs,
        MainFilters,
        ArMainContainer,
    },
})
export default class MainLab extends Vue {
    private searchValue = '';
    private lastDocList = {
        title: 'Останні додані',
        data: [
            {
                industry: 'Економічна концентрація',
                date: '25.12.2021',
                practice:
                    'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
                material: 'Шаблон',
            },
            {
                industry: 'Економічна концентрація',
                date: '25.12.2021',
                practice:
                    'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
                material: 'Шаблон',
            },
            {
                industry: 'Економічна концентрація',
                date: '25.12.2021',
                practice:
                    'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
                material: 'Шаблон',
            },
        ],
    };
    private viewDocList = {
        title: 'Ви дивилися',
        data: [
            {
                industry: 'Економічна концентрація',
                date: '25.12.2021',
                practice:
                    'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
                material: 'Шаблон',
            },
            {
                industry: 'Економічна концентрація',
                date: '25.12.2021',
                practice:
                    'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
                material: 'Шаблон',
            },
            {
                industry: 'Економічна концентрація',
                date: '25.12.2021',
                practice:
                    'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
                material: 'Шаблон',
            },
        ],
    };
}
