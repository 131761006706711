













































import { Component, Vue } from 'vue-property-decorator';

import ArMainContainer from '@/components/section/ar-main-container.vue';

import ArButton from '@/components/form/ar-button.vue';
import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import ArSelectSort from '@/components/form/ar-select-sort.vue';

import MainFilters from '@/components/block/main-filters.vue';
import FullDocs from '@/components/block/full-docs.vue';
import BookCards from '@/components/block/book-cards.vue';

@Component({
    name: 'Agreement',
    components: {
        ArMainContainer,

        ArButton,
        ArSearch,
        ArDownloadMore,
        ArSelectSort,

        MainFilters,
        FullDocs,
        BookCards,
    },
})
export default class Agreement extends Vue {
    private searchValue = '';
    private sortValue = '';
    private browsingHistoryList = [
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
    ];
    private books = [
        {
            name: 'The International Law of Investment Claims,',
            author: 'Zachary Douglas',
            date: '2018',
            poster: 'https://upload.wikimedia.org/wikipedia/uk/a/a6/Molly_Moon_and_the_Incredible_Book_of_Hypnotism_poster.jpg',
        },
        {
            name: 'The International Law of Investment Claims,',
            author: 'Zachary Douglas',
            date: '2018',
            poster: 'https://upload.wikimedia.org/wikipedia/uk/a/a6/Molly_Moon_and_the_Incredible_Book_of_Hypnotism_poster.jpg',
        },
        {
            name: 'The International Law of Investment Claims,',
            author: 'Zachary Douglas',
            date: '2018',
            poster: 'https://upload.wikimedia.org/wikipedia/uk/a/a6/Molly_Moon_and_the_Incredible_Book_of_Hypnotism_poster.jpg',
        },
        {
            name: 'The International Law of Investment Claims,',
            author: 'Zachary Douglas',
            date: '2018',
            poster: 'https://upload.wikimedia.org/wikipedia/uk/a/a6/Molly_Moon_and_the_Incredible_Book_of_Hypnotism_poster.jpg',
        },
    ];
}
