














































import { Component, InjectReactive, Vue } from 'vue-property-decorator';

import Avatar from '@/components/block/avatar.vue';

import { FullDocument } from '@/types/front-data-types/full-docs';
import { getDate } from '@/helpers/date';
import { sliceAuthorName } from '@/helpers/sliceAuthorName';

@Component({
    name: 'BookCreatedBy',
    components: {
        Avatar,
    },
})
export default class BookCreatedBy extends Vue {
    @InjectReactive() readonly document!: FullDocument;

    get createdAt(): string {
        return this.document.static_created_date
            ? getDate(this.document.static_created_date)
            : getDate(this.document.created_at);
    }

    get updatedAt(): string {
        return this.document.static_updated_date
            ? getDate(this.document.static_updated_date)
            : getDate(this.document.updated_at);
    }

    public correctAuthorName(authorName: string): string {
        return sliceAuthorName(authorName);
    }
}
