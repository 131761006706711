


















import { Component, Vue } from 'vue-property-decorator';
import ArMainContainer from '@/components/section/ar-main-container.vue';
import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import FullDocs from '@/components/block/full-docs.vue';
import SearchResultEmpty from '@/components/block/search-result-empty.vue';
import MainFilters from '@/components/block/main-filters.vue';

@Component({
    name: 'SearchFail',
    components: {
        ArSearch,
        FullDocs,
        SearchResultEmpty,
        ArDownloadMore,
        ArMainContainer,
        MainFilters,
    },
})
export default class SearchFail extends Vue {
    private searchValue = 'Доргкровірок';
}
