































import {
    Component,
    Emit,
    InjectReactive,
    Prop,
    Vue,
} from 'vue-property-decorator';
import { FullDocument } from '@/types/front-data-types/full-docs';

import ArButtonDownload from '@/components/form/ar-button-download.vue';
import ArButtonDocumentChoose from '@/components/form/ar-button-document-choose.vue';

import graphql from '@/plugins/graphql';
import { UpdateDocumentRequestType } from '@/types/requests/documents';
import UpdateDocumentDownloads from '@/graphql/documents/UpdateDocumentDownloads.gql';
import { Mutation } from 'vuex-class';

@Component({
    name: 'DocumentSidebar',
    components: {
        ArButtonDownload,
        ArButtonDocumentChoose,
    },
})
export default class DocumentSidebar extends Vue {
    @Prop({ default: false })
    readonly isChosen: boolean;

    @InjectReactive() readonly document!: FullDocument;
    private downloadsCount = 0;

    @Mutation('Search/SET_SEARCH_VALUE')
    public SET_SEARCH_VALUE!: (value: string) => void;

    get downloads(): number {
        console.log(this.downloadsCount, this.document.downloads);
        return this.downloadsCount + this.document.downloads;
    }

    private get docUrl(): string {
        console.log('doc', this.document);
        const serverDocName = this.document.document
            ? this.document.document.publicUrl.split('/').pop()
            : this.document.document_url.split('/').pop();

        return this.document.document
            ? `${process.env.VUE_APP_STATIC_URL}/download?serverDocName=${serverDocName}&docName=${this.document.title}`
            : `/download?serverDocName=${serverDocName}&docName=${this.document.title}`;
    }

    @Emit('addToChosen')
    addToChosen(): string | undefined {
        return this.document.id;
    }

    async downloadCounter(): Promise<void> {
        const res = await graphql<UpdateDocumentRequestType>(
            UpdateDocumentDownloads,
            {
                id: this.document.id,
                downloads: this.downloads + 1,
            }
        );
        console.log({ res });
    }

    public searchByTag(tag: string): void {
        this.SET_SEARCH_VALUE(`#${tag}`);
    }
}
