import axios from "axios";

export default async function <ReturnType>(
    code: string,
    verifier: string
): Promise<ReturnType | false> {
    try {
        const url = `https://login.microsoftonline.com/${process.env.VUE_APP_MS_TENANT}/oauth2/v2.0/token`;
        const redirectUri = process.env.VUE_APP_MS_REDIRECT || "http://localhost:8080/login/";
        const clientId = process.env.VUE_APP_MS_CLIENT || '3dc4b0b0-ab72-425d-b296-c9ba3ad3d098';
        
        const params = new URLSearchParams()
        params.append('grant_type', 'authorization_code')
        params.append('client_id', clientId)
        params.append('scope', 'https://graph.microsoft.com/user.read')
        params.append('code', code)
        params.append('code_verifier', verifier)
        params.append('redirect_uri', redirectUri)
        const response = await axios.post(url,
            params,
            {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                }
            }
        );
        return response.data
    } catch (e) {
        return false;
    }
}
