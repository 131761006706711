








































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import ArMainContainer from '@/components/section/ar-main-container.vue';
import ArMainSidebar from '@/components/section/ar-main-sidebar.vue';

import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import ArSelectSort from '@/components/form/ar-select-sort.vue';

import Preloader from '@/components/block/preloader.vue';
import FullDocs from '@/components/block/full-docs.vue';
import MainFilters from '@/components/block/main-filters.vue';
import SearchResultEmpty from '@/components/block/search-result-empty.vue';

import { Filter } from '@/types/front-data-types/filter';

@Component({
    name: 'Search',
    components: {
        ArMainSidebar,
        ArSearch,
        Preloader,
        FullDocs,
        ArDownloadMore,
        SearchResultEmpty,
        ArSelectSort,
        ArMainContainer,
        MainFilters,
    },
})
export default class Search extends Vue {
    private preloaderIsVisible = false;

    // region VUEX
    @Getter('Search/SEARCH_VALUE')
    private SEARCH_VALUE: string;
    @Getter('Search/IS_SEARCH_TAG')
    private IS_SEARCH_TAG: string;
    @Getter('Sort/SORT_VALUE')
    private SORT_VALUE: string;

    @Getter('SSF/FILTER_RESULT')
    private FILTER_RESULT;
    @Getter('SSF/SEARCH_RESULT')
    private SEARCH_RESULT;

    @Getter('Filters/SELECTED_FILTERS')
    private SELECTED_FILTERS: Filter;

    @Action('SSF/LOAD_SSF_RESULT')
    public LOAD_SSF_RESULT!: (payload: {
        isBook: boolean;
        isLoadMore: boolean;
        searchedListPropName?: string;
    }) => Promise<void>;
    // endregion

    // region WATCHERS
    @Watch('SELECTED_FILTERS', { deep: true })
    async onFiltersChanged(): Promise<void> {
        await this.loadData(async (): Promise<void> => {
            await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
        });
    }
    @Watch('SEARCH_VALUE')
    async onSearchValueChanged(): Promise<void> {
        await this.loadData(async (): Promise<void> => {
            await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
        });
        console.log('art', this.articles);
    }

    @Watch('SORT_VALUE')
    async onSortValueChanged(): Promise<void> {
        await this.loadData(async (): Promise<void> => {
            await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
        });
    }
    // endregion

    // region COMPUTED
    get articles(): Record<string, unknown> {
        if (this.SORT_VALUE === 'Спочатку — в назві документу') {
            return {
                inTitle: {
                    title: 'В назві документу',
                    data: this.SEARCH_RESULT.inTitle,
                },
                inText: {
                    title: 'В тексті документу',
                    data: this.SEARCH_RESULT.inText,
                },
                inBook: {
                    title: 'У назві чи описі книги',
                    data: this.SEARCH_RESULT.inBook,
                },
                inTags: {
                    title: '#Теги в документі',
                    data: this.SEARCH_RESULT.inTags,
                },
            };
        } else if (this.SORT_VALUE === 'Спочатку — в тексті документу') {
            return {
                inText: {
                    title: 'В тексті документу',
                    data: this.SEARCH_RESULT.inText,
                },
                inTitle: {
                    title: 'В назві документу',
                    data: this.SEARCH_RESULT.inTitle,
                },
                inBook: {
                    title: 'У назві чи описі книги',
                    data: this.SEARCH_RESULT.inBook,
                },
                inTags: {
                    title: '#Теги в документі',
                    data: this.SEARCH_RESULT.inTags,
                },
            };
        } else if (this.SORT_VALUE === 'Спочатку — в назві чи описі книги') {
            return {
                inBook: {
                    title: 'У назві чи описі книги',
                    data: this.SEARCH_RESULT.inBook,
                },
                inText: {
                    title: 'В тексті документу',
                    data: this.SEARCH_RESULT.inText,
                },
                inTitle: {
                    title: 'В назві документу',
                    data: this.SEARCH_RESULT.inTitle,
                },
                inTags: {
                    title: '#Теги в документі',
                    data: this.SEARCH_RESULT.inTags,
                },
            };
        } else {
            return {
                inTags: {
                    title: '#Теги в документі',
                    data: this.SEARCH_RESULT.inTags,
                },
                inTitle: {
                    title: 'В назві документу',
                    data: this.SEARCH_RESULT.inTitle,
                },
                inText: {
                    title: 'В тексті документу',
                    data: this.SEARCH_RESULT.inText,
                },
                inBook: {
                    title: 'У назві чи описі книги',
                    data: this.SEARCH_RESULT.inBook,
                },
            };
        }
    }

    get resultCount(): number | null {
        return (
            Number(this.FILTER_RESULT.count) +
            Number(this.SEARCH_RESULT.inTitle.count) +
            Number(this.SEARCH_RESULT.inText.count) +
            Number(this.SEARCH_RESULT.inBook.count) +
            Number(this.SEARCH_RESULT.inTags.count)
        );
    }
    // endregion

    // region METHODS
    async loadData(func: () => Promise<void>): Promise<void> {
        this.preloaderIsVisible = true;
        await func();
        this.preloaderIsVisible = false;
    }
    // endregion

    async created(): Promise<void> {
        await this.loadData(async (): Promise<void> => {
            await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
        });
    }
}
