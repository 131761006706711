








































import { Component, Vue } from 'vue-property-decorator';

import ArMainContainer from '@/components/section/ar-main-container.vue';

import ArButton from '@/components/form/ar-button.vue';
import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import ArSelectSort from '@/components/form/ar-select-sort.vue';

import MainFilters from '@/components/block/main-filters.vue';
import FullDocs from '@/components/block/full-docs.vue';
import BookCards from '@/components/block/book-cards.vue';

@Component({
    name: 'Books',
    components: {
        ArMainContainer,

        ArButton,
        ArSearch,
        ArDownloadMore,
        ArSelectSort,

        MainFilters,
        FullDocs,
        BookCards,
    },
})
export default class Books extends Vue {
    private searchValue = '';
    private columnCount = 3;
    private books = [
        {
            name: 'The International Law of Investment Claims,',
            author: 'Zachary Douglas',
            date: '2018',
            poster: 'https://upload.wikimedia.org/wikipedia/uk/a/a6/Molly_Moon_and_the_Incredible_Book_of_Hypnotism_poster.jpg',
        },
        {
            name: 'The International Law of Investment Claims,',
            author: 'Zachary Douglas',
            date: '2018',
            poster: 'https://upload.wikimedia.org/wikipedia/uk/a/a6/Molly_Moon_and_the_Incredible_Book_of_Hypnotism_poster.jpg',
        },
        {
            name: 'The International Law of Investment Claims,',
            author: 'Zachary Douglas',
            date: '2018',
            poster: 'https://upload.wikimedia.org/wikipedia/uk/a/a6/Molly_Moon_and_the_Incredible_Book_of_Hypnotism_poster.jpg',
        },
        {
            name: 'The International Law of Investment Claims,',
            author: 'Zachary Douglas',
            date: '2018',
            poster: 'https://upload.wikimedia.org/wikipedia/uk/a/a6/Molly_Moon_and_the_Incredible_Book_of_Hypnotism_poster.jpg',
        },
    ];
}
