





















import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'ErrorDocument',
})
export default class ErrorDocument extends Vue {
    private isVisible = true;
}
