var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-main-container',[_c('ar-main-sidebar',[_c('main-filters',{attrs:{"result-count":_vm.resultCount}})],1),_c('div',{staticClass:"searchPage"},[_c('header',{staticClass:"searchPage_header"},[_c('ar-search',{attrs:{"is-redirect":false}}),_c('ar-select-sort')],1),(_vm.preloaderIsVisible)?_c('preloader'):_vm._e(),(!_vm.preloaderIsVisible && _vm.resultCount < 1)?_c('search-result-empty'):_vm._e(),(!_vm.SEARCH_VALUE)?_c('section',{staticClass:"searchPage_body"},[_c('article',{staticClass:"searchPage_body_article"},[_c('full-docs',{attrs:{"list-data":_vm.FILTER_RESULT.list}}),(_vm.FILTER_RESULT.count !== 0)?_c('footer',{staticClass:"searchPage_footer"},[_c('ar-download-more',{attrs:{"loaded-count":_vm.FILTER_RESULT.list.length,"total-count":_vm.FILTER_RESULT.count},on:{"load-more":function($event){return _vm.LOAD_SSF_RESULT({
                                isBook: false,
                                isLoadMore: true,
                            })}}})],1):_vm._e()],1)]):_c('section',{staticClass:"searchPage_body"},_vm._l((_vm.articles),function(article,propName){return _c('article',{key:propName,staticClass:"searchPage_body_article"},[(article.data.list.length)?_c('h3',{staticClass:"searchPage_body_article-title"},[_vm._v(" "+_vm._s(article.title)+" ")]):_vm._e(),_c('full-docs',{attrs:{"list-data":article.data.list,"is-read":propName === 'inText'}}),(article.data.count !== 0 && article.data.list)?_c('footer',{staticClass:"searchPage_footer"},[_c('ar-download-more',{attrs:{"loaded-count":article.data.list.length,"total-count":article.data.count},on:{"load-more":function($event){return _vm.LOAD_SSF_RESULT({
                                isBook: false,
                                isLoadMore: true,
                                searchedListPropName: propName,
                            })}}})],1):_vm._e()],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }