















import { Component, Prop, Vue } from 'vue-property-decorator';
import Preloader from '@/components/block/preloader.vue';

@Component({
    name: 'FileViewer',
    components: {
        Preloader,
    },
})
export default class FileViewer extends Vue {
    @Prop({ default: '' }) readonly src: string;

    $refs!: {
        viewer: HTMLIFrameElement;
    };

    private isLoaded = false;

    iframeLoaded(): void {
        console.log('LOADED!)))');
        this.isLoaded = true;
    }

    mounted(): void {
        const interval = setInterval(() => {
            if (this.$refs.viewer && this.$refs.viewer.contentWindow) {
                try {
                    this.$refs.viewer.contentWindow.location.reload();
                } catch (e) {
                    return;
                }
            }
            if (this.isLoaded) {
                clearInterval(interval);
            }
        }, 1000);
    }
}
