



































































































import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator';

import ArMainContainer from '@/components/section/ar-main-container.vue';
import ArMainSidebar from '@/components/section/ar-main-sidebar.vue';
import DocumentSidebar from '@/components/section/document-sidebar.vue';

import ArButton from '@/components/form/ar-button.vue';
import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import ArSelectSort from '@/components/form/ar-select-sort.vue';

import MainFilters from '@/components/block/main-filters.vue';
import FullDocs from '@/components/block/full-docs.vue';
import BookCards from '@/components/block/book-cards.vue';
import Comments from '@/components/block/comments.vue';
import FileViewer from '@/components/block/file-viewer.vue';
import DocCreatedBy from '@/components/block/doc-created-by.vue';
import BookCreatedBy from '@/components/block/book-created-by.vue';
import EditText from '@/components/block/edit-text.vue';
import BookPoster from '@/components/block/book-poster.vue';
import Preloader from '@/components/block/preloader.vue';
import Like from '@/components/block/like.vue';
import ActionView from '@/components/block/view.vue';

import ErrorDocument from '@/components/hint/error-document.vue';

import graphql from '@/plugins/graphql';
import GetDocument from '@/graphql/documents/GetDocument.gql';
import GetRelatedDocs from '@/graphql/documents/GetRelatedDocs.gql';
import GetDocumentList from '@/graphql/documents/GetDocumentList.gql';
import GetBookList from '@/graphql/documents/GetBookList.gql';
import ReportDocError from '@/graphql/documents/reportDocError.graphql';

import {
    defaultFullDocument,
    defaultFullDocumentList,
    FullDocument,
    FullDocumentList,
} from '@/types/front-data-types/full-docs';

import { clone } from '@/helpers/objects';
import {
    DocRelatedResp,
    DocumentResponse,
    DocumentsByFiltersRequest,
    ReportDocErrorReq,
} from '@/types/requests/documents';
import { Action, Getter } from 'vuex-class';
import { UserType } from '@/types/front-data-types/users';

export type ReportData = {
    desc: string;
    text: string | undefined;
};

@Component({
    name: 'Document',
    components: {
        ArMainContainer,
        ArMainSidebar,
        DocumentSidebar,

        ArButton,
        ArSearch,
        ArDownloadMore,
        ArSelectSort,

        MainFilters,
        FullDocs,
        BookCards,
        Comments,
        FileViewer,
        DocCreatedBy,
        BookCreatedBy,
        EditText,
        BookPoster,
        Preloader,
        Like,
        ActionView,

        ErrorDocument,
    },
})
export default class Document extends Vue {
    private books: FullDocumentList = clone(defaultFullDocumentList);
    private preloaderIsVisible = true;

    @Getter('Users/CHECK_IS_DOC_LIKED_BY_USER')
    isLiked: (DocId: string) => boolean;
    @Getter('Users/CHECK_IS_DOC_CHOSEN_BY_USER')
    isChosen: (DocId: string) => boolean;
    @Action('Users/CHECK_IS_DOC_VIEWED_BY_USER')
    isViewed: (DocId: string) => boolean;
    @Action('Users/VIEW_DOC_IF_NOT_VIEWED')
    viewIfNotViewed: (DocId: string) => boolean;
    @Action('Users/CLICK_ON_DOCUMENT_LIKE_BTN')
    storeToggleLike: (DocId: string) => boolean;
    @Action('Users/CLICK_ON_DOCUMENT_CHOSE_BTN')
    storeToggleChose: (DocId: string) => boolean;
    @ProvideReactive() document: FullDocument = clone(defaultFullDocument);
    private relatedDocuments: FullDocumentList = clone(defaultFullDocumentList);
    @Getter('Search/SEARCH_VALUE')
    private searchValue: string;
    @Getter('Users/USER')
    private user: UserType;

    get basicUrl(): string {
        return process.env.VUE_APP_STATIC_URL;
    }

    get docDescription(): string {
        let tmp = document.createElement('DIV');
        tmp.innerHTML = this.document.full_description;
        return tmp.textContent || tmp.innerText || '';
    }

    @Watch('$route', { deep: true })
    onPropertyChanged(): void {
        this.setAllData();
    }

    async errorReported(data: ReportData): Promise<void> {
        console.log(data);
        const res = await graphql<ReportDocErrorReq>(ReportDocError, {
            selected: data.text,
            desc: data.desc,
            document: this.document.id,
        });
        if (res && res.createDocErrorReport && res.createDocErrorReport.id) {
            alert('Спасибо! Мы обязательно исправимся');
        }
    }

    async toggleLike(docId: string): Promise<void> {
        this.storeToggleLike(docId);
        await this.loadDocument(docId);
    }

    async toggleChose(docId: string): Promise<void> {
        console.log('shoos');
        this.storeToggleChose(docId);
        await this.loadDocument(docId);
    }

    async loadDocument(id: string): Promise<void> {
        const doc = graphql<DocumentResponse>(GetDocument, {
            where: {
                id: `${id}`,
            },
        });
        const docRelated = graphql<DocRelatedResp>(GetRelatedDocs, {
            doc: `${id}`,
        });
        const result = await Promise.all([doc, docRelated]);
        if (result[0] && result[0].Document) {
            this.document = clone(result[0].Document);
        }
        if (result[1] && result[1].Document) {
            this.relatedDocuments = clone(result[1].Document.related);
        }
        this.preloaderIsVisible = false;
        window.scrollTo(0, 0);
    }

    public async setAllData(): Promise<void> {
        const documentId = this.$route.params.id as string;
        const relatedDocuments = await graphql<DocumentsByFiltersRequest>(
            GetDocumentList
        );
        await this.loadDocument(documentId);
        const books = await graphql<DocumentsByFiltersRequest>(GetBookList, {
            skip: 0,
            perPage: 4,
        });
        if (!relatedDocuments) return;
        this.relatedDocuments = clone(relatedDocuments.allDocuments);
        if (!books) return;
        this.books = clone(books.allDocuments);

        if (this.document && this.document.id) {
            this.viewIfNotViewed(this.document.id);
            await this.loadDocument(this.document.id);
        }
    }

    async created(): Promise<void> {
        await this.setAllData();
    }
}
