










































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import {
    CommentsRequestType,
    CommentType,
    CreateCommentType,
} from '@/types/requests/documents';
import { UserType } from '@/types/front-data-types/users';
import { Getter } from 'vuex-class';
import graphql from '@/plugins/graphql';
import Avatar from '@/components/block/avatar.vue';

import CreateComment from '@/graphql/documents/CreateComment.graphql';
import CommentsByDoc from '@/graphql/documents/commentsByDoc.gql';

import { clone } from '@/helpers/objects';
import {
    defaultFullDocument,
    FullDocument,
} from '@/types/front-data-types/full-docs';
import { FileType } from '@/types/front-data-types/base/file';
import { sliceAuthorName } from '@/helpers/sliceAuthorName';

@Component({ name: 'Comments', components: { Avatar } })
export default class Comments extends Vue {
    @Prop({ default: () => clone(defaultFullDocument) })
    readonly document: FullDocument;

    private comments: CommentType[] = [];
    private showReply = '0';
    private commentText = '';
    private commentReplyText = '';

    @Getter('Users/USER')
    private user: UserType;

    get avatar(): FileType | boolean {
        if (this.user && this.user.avatar) {
            return this.user.avatar;
        } else {
            return false;
        }
    }

    @Watch('document', { deep: true })
    postWatcher(newVal: FullDocument | null): void {
        if (newVal && newVal.id) {
            this.updateComments();
        }
    }

    timeFromNow(date: string): string {
        return moment(date).fromNow();
    }

    showReplyInput(comment: CommentType): void {
        if (comment.id && this.showReply !== comment.id) {
            this.showReply = comment.id;
        } else {
            this.showReply = '0';
        }
    }

    async updateComments(): Promise<void> {
        const documentCommentsRequest = await graphql<CommentsRequestType>(
            CommentsByDoc,
            { id: this.document.id }
        );
        console.log('Comments updated: ', documentCommentsRequest);
        if (documentCommentsRequest) {
            this.comments = documentCommentsRequest.allDocumentComments;
        }
    }

    async createComment(commentId: string | null = null): Promise<void> {
        console.log(this.user);
        const comment: Record<string, unknown> = {
            text: this.commentText,
            document: { connect: { id: this.document.id } },
            author: { connect: { id: this.user.id } },
        };
        if (commentId) {
            comment.text = this.commentReplyText;
            comment.parent = { connect: { id: commentId } };
        }
        const createCommentRequest = await graphql<CreateCommentType>(
            CreateComment,
            { data: comment }
        );
        if (createCommentRequest) {
            await this.updateComments();
        }
        this.commentText = '';
        this.commentReplyText = '';
        this.showReply = '0';
    }

    public correctAuthorName(authorName: string): string {
        return sliceAuthorName(authorName);
    }
}
