


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ArButtonDownload from '@/components/form/ar-button-download.vue';
import Comments from '@/components/block/comments.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import {
    defaultFullDocument,
    FullDocument,
} from '@/types/front-data-types/full-docs';
import { clone } from '@/helpers/objects';
import UpdateDocumentDownloads from '@/graphql/documents/UpdateDocumentDownloads.gql';
import graphql from '@/plugins/graphql';
import { UpdateDocumentRequestType } from '@/types/requests/documents';

import ActionView from '@/components/block/view.vue';

@Component({
    name: 'InternalDoc',
    components: {
        ArDownloadMore,
        ArButtonDownload,
        Comments,
        ActionView,
    },
})
export default class InternalDoc extends Vue {
    @Prop({ default: clone(defaultFullDocument) })
    readonly document: FullDocument;

    // private commentIsVisible = false;
    private downloadsCount = 0;

    get url(): string {
        const serverDocName = this.document.document
            ? this.document.document.publicUrl.split('/').pop()
            : this.document.document_url.split('/').pop();
        return this.document.document && this.document.document.publicUrl
            ? `${process.env.VUE_APP_STATIC_URL}/download?serverDocName=${serverDocName}&docName=${this.document.title}`
            : `/download?serverDocName=${serverDocName}&docName=${this.document.title}`;
    }

    get downloads(): number {
        console.log(this.downloadsCount, this.document.downloads);
        return this.downloadsCount + this.document.downloads;
    }

    async downloadCounter(): Promise<void> {
        const res = await graphql<UpdateDocumentRequestType>(
            UpdateDocumentDownloads,
            {
                id: this.document.id,
                downloads: this.downloads + 1,
            }
        );
        console.log({ res });
        if (res && res.updateDocument.downloads) {
            this.downloadsCount += 1;
        }
    }
}
