










import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'Preloader',
})
export default class Preloader extends Vue {}
