




































import { Component, Vue } from 'vue-property-decorator';
import ArMainContainer from '@/components/section/ar-main-container.vue';
import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import ArSelectSort from '@/components/form/ar-select-sort.vue';
import FullDocs from '@/components/block/full-docs.vue';
import MainFilters from '@/components/block/main-filters.vue';

@Component({
    name: 'SearchFilter',
    components: {
        ArSearch,
        FullDocs,
        ArDownloadMore,
        ArSelectSort,
        ArMainContainer,
        MainFilters,
    },
})
export default class SearchFilter extends Vue {
    private searchValue = 'Договір';
    private browsingHistoryList = [
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
        {
            industry: 'Економічна концентрація',
            date: '03.03.21',
            practice:
                'Договір про надання послуг зі збирання, сортування, транспортування та утилізації використаних пакувальних матеріалів і тари з-під пестицидів та агрохімікатів',
            material: 'Шаблон',
            author: 'Yevheniia Chernetsova',
            lang: 'Укр',
            desc: 'Зразок заяви до господарського суду про припинення представництва сторони у справі, яке здійснювалося на підставі довіреності. ',
            type: 'Договір ',
        },
    ];
}
