












import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'VCheckbox' })
export default class VCheckbox extends Vue {}
