
























import { Component, Vue } from 'vue-property-decorator';
import ArMainContainer from '@/components/section/ar-main-container.vue';
import ArMainSidebar from '@/components/section/ar-main-sidebar.vue';

import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import ArSelectSort from '@/components/form/ar-select-sort.vue';

import FullDocs from '@/components/block/full-docs.vue';
import MainFilters from '@/components/block/main-filters.vue';

import { Getter } from 'vuex-class';
import { UserType } from '@/types/front-data-types/users';
import graphql from '@/plugins/graphql';
import { ViewedDocumentList } from '@/types/requests/documents';
import GetViewedDocumentList from '@/graphql/documents/GetViewedDocumentList.gql';
import { clone } from '@/helpers/objects';
import {
    defaultFullDocumentListFrontData,
    FullDocumentListFrontData,
} from '@/types/front-data-types/full-docs';

@Component({
    name: 'BrowsingHistory',
    components: {
        ArSearch,
        FullDocs,
        ArDownloadMore,
        ArSelectSort,
        ArMainContainer,
        ArMainSidebar,
        MainFilters,
    },
})
export default class BrowsingHistory extends Vue {
    private viewedDocumentList: FullDocumentListFrontData = clone(
        defaultFullDocumentListFrontData
    );

    @Getter('Users/USER')
    private USER: UserType;

    get viewedDocuments(): any[] {
        return this.viewedDocumentList.list.map((documentItem) => {
            return documentItem.document;
        });
    }

    public async setAllData(isLoadMore: boolean): Promise<void> {
        const token = localStorage.getItem('authToken') + '';
        const queryObject = {
            token,
            sort:
                isLoadMore && this.viewedDocumentList.list
                    ? this.viewedDocumentList.list.length
                    : undefined,
        };
        const viewedDocuments = await graphql<ViewedDocumentList>(
            GetViewedDocumentList,
            queryObject
        );

        if (!viewedDocuments) return;
        if (!viewedDocuments.allUsers[0]) return;

        if (isLoadMore) {
            this.viewedDocumentList.list = clone([
                ...this.viewedDocumentList.list,
                ...viewedDocuments.allUsers[0].viewedDocuments,
            ]);
        } else {
            this.viewedDocumentList.list = clone(
                viewedDocuments.allUsers[0].viewedDocuments
            );
        }

        this.viewedDocumentList.count = clone(
            viewedDocuments.allUsers[0]._viewedDocumentsMeta?.count
        );
    }

    created(): void {
        this.setAllData(false);
    }
}
