





























import { Component, Vue } from 'vue-property-decorator';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import InternalDoc from '@/components/block/internal-doc.vue';
import graphql from '@/plugins/graphql';
import InternalDocsDepartments from '@/graphql/departments/internalDocumentsByDepartment.graphql';
import {
    AllDocumentsDepartmentsRequest,
    DepartmentType,
} from '@/types/requests/filters';
import { DocumentsByFiltersRequest } from '@/types/requests/documents';
import GetDocumentsByDepartment from '@/graphql/documents/GetInternalDocumentsByDepartment.graphql';

@Component({
    name: 'InternalDocuments',
    components: {
        ArDownloadMore,
        InternalDoc,
    },
})
export default class InternalDocuments extends Vue {
    private docsByDepartments: DepartmentType[] = [];
    private perPage = 5;

    get depsWithDocs(): DepartmentType[] {
        return this.docsByDepartments.filter((dep) => {
            return dep.documents.length > 0;
        });
    }

    async created(): Promise<void> {
        const docsByType = await graphql<AllDocumentsDepartmentsRequest>(
            InternalDocsDepartments,
            {
                perPage: this.perPage,
            }
        );
        if (docsByType && docsByType.allInternalDocsDepartments) {
            this.docsByDepartments = docsByType.allInternalDocsDepartments;
        }
    }

    private async loadMore(docDepartId: string | number): Promise<void> {
        const skip = this.getSkipByDocDep(docDepartId);
        const newDocs = await graphql<DocumentsByFiltersRequest>(
            GetDocumentsByDepartment,
            {
                departmentId: docDepartId,
                skip: skip,
                perPage: this.perPage,
            }
        );
        if (newDocs && newDocs.allDocuments) {
            const docDep = this.getDocDepById(docDepartId);
            if (docDep) {
                const oldDocs = docDep.documents;
                docDep.documents = [...oldDocs, ...newDocs.allDocuments];
            }
        }
    }

    private getSkipByDocDep(docDepId: string | number) {
        const docDep = this.getDocDepById(docDepId);
        if (docDep && docDep.documents) {
            return docDep.documents.length;
        }
        return 0;
    }

    private getDocDepById(
        docDepId: string | number
    ): DepartmentType | undefined {
        return this.docsByDepartments.find(
            (department) => department.id === docDepId
        );
    }
}
