















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
export type ReportData = {
    desc: string;
    text: string | undefined;
};

@Component({
    name: 'EditText',
})
export default class EditText extends Vue {
    @Prop({ default: 'Опишите ошибку' }) readonly promptText: string;

    private editIcon = {
        isVisible: false,
        top: 0,
        left: 0,
    };
    private selectedText: string | undefined = '';

    @Emit('reported')
    emitData(desc: string, text: string | undefined): ReportData {
        return { desc, text };
    }

    public getSelectedText(e: MouseEvent): void {
        let selectedText: string | undefined = '';
        if (window && document) {
            if (window.getSelection) {
                selectedText = window?.getSelection()?.toString();
            }
        }
        this.selectedText = selectedText;
        if (this.selectedText) {
            this.editIcon.isVisible = true;
            this.editIcon.top = e.clientY - 40;
            this.editIcon.left = e.clientX - 10;
        } else {
            this.editIcon.isVisible = false;
        }
    }

    public showPrompt(): void {
        const msg = prompt(this.promptText);
        if (msg) {
            this.emitData(msg, this.selectedText);
        }
        this.editIcon.isVisible = false;
    }
}
