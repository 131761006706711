































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
    defaultFullDocument,
    FullDocument,
} from '@/types/front-data-types/full-docs';
import { Getter } from 'vuex-class';

import Avatar from '@/components/block/avatar.vue';

@Component({ name: 'Like', components: { Avatar } })
export default class Like extends Vue {
    @Prop({ default: defaultFullDocument }) readonly document: FullDocument;

    @Getter('Users/CHECK_IS_DOC_LIKED_BY_USER')
    isLiked: (DocId: string) => boolean;

    @Emit('toggleLike')
    toggleLike(): string | undefined {
        return this.document.id;
    }
}
