

























































import { Component, InjectReactive, Vue } from 'vue-property-decorator';

import Avatar from '@/components/block/avatar.vue';

import { FullDocument } from '@/types/front-data-types/full-docs';
import { getDate } from '@/helpers/date';
import { sliceAuthorName } from '@/helpers/sliceAuthorName';

@Component({
    name: 'DocCreatedBy',
    components: {
        Avatar,
    },
})
export default class DocCreatedBy extends Vue {
    @InjectReactive() readonly document!: FullDocument;

    private get languages(): string {
        let lang: string[] = [];
        if (
            this.document &&
            this.document.language &&
            this.document.language.name
        ) {
            lang.push(this.document.language.name);
        }
        if (
            this.document &&
            this.document.additional_language &&
            this.document.additional_language.name
        ) {
            lang.push(this.document.additional_language.name);
        }
        return lang.join(', ');
    }

    get createdAt(): string {
        return this.document.static_created_date
            ? getDate(this.document.static_created_date)
            : getDate(this.document.created_at);
    }

    get updatedAt(): string {
        return this.document.static_updated_date
            ? getDate(this.document.static_updated_date)
            : getDate(this.document.updated_at);
    }

    public correctAuthorName(authorName: string): string {
        return sliceAuthorName(authorName);
    }
}
