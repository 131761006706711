











import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'ArPassword' })
export default class ArPassword extends Vue {}
